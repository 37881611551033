import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import SignInView from "@/views/signin/SignInView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'signin',
    component: SignInView
  },
  {
    path: '/home',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/homesinistri',
    name: 'homesinistri',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/sinistri/HomeSinistriView.vue')
  },
  {
    path: '/listsinistri',
    name: 'listsinistri',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/sinistri/ListSinistriView.vue')
  },
  {
    path: '/editsinistri',
    name: 'editsinistri',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/sinistri/EditSinistriView.vue')
  },
  {
    path: '/homemagazzino',
    name: 'homemagazzino',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/magazzino/HomeMagazzinoView.vue')
  },
  {
    path: '/editscaricomagazzino',
    name: 'editscaricomagazzino',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/magazzino/EditScaricoMagazzino.vue')
  },
  {
    path: '/editscaricomagazzinordo',
    name: 'editscaricomagazzinordo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/magazzino/EditScaricoMagazzinoRdo.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
/*
  if (to.name === 'signin' || to.name === 'forgot' || to.name === 'reset' || to.name === 'camera') {
    navStore.setNav(false)
  } else {
    navStore.setNav(true)
  }
*/
  if (!to.meta.requiresAuth) {
    next();
    return;
  }
  if (!localStorage.getItem('user')) {
    next({ name: 'signin' })
  } else {
    next()
  }
})

export default router
