import {Ref, ref} from "vue";
import axios from "axios";

const apiString=process.env.VUE_APP_API_USER+":"+process.env.VUE_APP_API_PASSWORD;

export function isAuthenticated() {
    return !!localStorage.getItem('user');
}

export function getLoggedUserFromStorage() {
    let user ={} as { name: string; surname: string };
    const userStorage = localStorage.getItem('user');
    if (userStorage) {
        user = JSON.parse(userStorage);
    }
    return user;
}

export function logout() {
    localStorage.removeItem('user');
}

export function useFetchItems(apiUrl:string) {
    const items: Ref<string[]> = ref([]);
    const loading = ref(false);
    const cache = new Map(); // Cache per evitare chiamate ripetitive

    const fetchItems = async () => {
        loading.value = true;

        if (cache.has(apiUrl)) {
            items.value = [...cache.get(apiUrl)] as never;
        } else {
            try {
                const response = await axios.get(process.env.VUE_APP_BASE_API_URL + apiUrl, {
                    headers: {
                        'Content-Type':  'application/json',
                        Authorization: 'Basic ' + btoa(apiString)
                    }
                });

                items.value = response.data.data;
                cache.set(apiUrl, response.data.data);
            } catch (e) {
                console.log('Errore nel fetching degli items',e);
            }
        }
        loading.value = false;
    };

    return { items, loading, fetchItems };
}