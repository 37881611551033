export const locales = {
  en: {
    accept: 'Yes',
    reject: 'No',
    // Aggiungi altre chiavi di traduzione se necessario
  },
  it: {
    accept: 'Sì',
    emptyFilterMessage: 'Nessun filtro trovato',
    emptySearchMessage: 'La ricerca non ha prodotto risultati utili.',
    // Aggiungi altre chiavi di traduzione se necessario
  },
}