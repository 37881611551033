<template>
  <div class="bg-gray-300 dark:bg-black min-h-screen w-full overflow-y-auto">
    <Toast class="m-5" position="center" group="bc" @close="onClose">
      <template #message="slotProps">
        <div class="flex flex-col items-start flex-auto">
          <div class="flex items-center">
            <span class="font-bold">{{ slotProps.message.summary }}</span>
          </div>
          <div class="font-medium text-lg my-4">{{ slotProps.message.detail }}</div>
          <Button size="small" label="Forza aggiornamento" severity="success" @click="onReply()"></Button>
        </div>
      </template>
    </Toast>

    <router-view />
  </div>
</template>

<script setup language="ts">
import Toast from "primevue/toast";
import Button from "primevue/button";
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';

const toast = useToast();
const visible = ref(false);

// Ascolta l'evento 'swUpdated' e mostra un toast quando viene emesso
onMounted(() => {
  const handleUpdate = () => {
    // Controlla se il toast è già stato mostrato in questa sessione
    if (sessionStorage.getItem('swUpdated') === 'true') return;

    toast.add({
      severity: 'info',
      group: 'bc',
      summary: 'Aggiornamento disponibile',
      detail: "L'aggiornamento si applica in automatico fra pochi secondi, oppure forzalo tu cliccando sul bottone.",
      life: 0 // Resta finché non viene chiuso manualmente
    });

    visible.value = true;

    // Imposta che l'evento è stato gestito
    sessionStorage.setItem('swUpdated', 'true');

    // Rimuove l'ascoltatore per sicurezza
    document.removeEventListener('swUpdated', handleUpdate);
  };
  // Aggiunge l'evento con `once: true` per evitarlo più volte
  document.addEventListener('swUpdated', handleUpdate, { once: true });
});

const onReply = () => {
  toast.removeGroup('bc');
  visible.value = false;
  window.location.reload(true);  // Forza il reload della pagina
}

const onClose = () => {
  visible.value = false;
  window.location.reload(true);  // Forza il reload della pagina
}
</script>